import CareCredit from '@/care-credit';
import { SEO } from '@/common/seo';

const CareCreditPage = () => {
    return <CareCredit />;
};

export default CareCreditPage;

export const Head = () => (
    <SEO
        title="Carecredit Healthcare - Financing for Dental Procedures"
        description="Carecredit is a credit card that helps you to pay for treatments and procedures for  you and your family that may not be covered by insurance."
        pathname="/care-credit/"
    />
);
