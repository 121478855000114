import Button from '@/common/button';

const PaymentCard = () => {
    return (
        <div className="w-full md:min-w-[200px] lg:min-w-[575px] max-w-[575px] lg:ml-8  py-12 px-8 rounded-2xl shadow-CardShadow">
            <p className="DH4MH3 text-green">Payment Calculator</p>
            <h1 className="DP1MH3 pt-8">Estimated Procedure Amount</h1>
            <input
                className={`pt-4 bg-transparent border-b-2 w-full  focus:border-green focus:text-green outline-none `}
                placeholder="$"
            />
            <p className="FormText text-grayT text-center my-10">
                *Subject to credit approval. Minimum monthly payments required. Visit
                www.carecredit.com for details.
            </p>
            <a href="https://www.carecredit.com/go/677BVH/" target="_blank" rel="noreferrer">
                <Button
                    className="bg-primary text-white border-none mx-auto"
                    text="Calculate Amount Payable"
                />
            </a>
        </div>
    );
};

export default PaymentCard;
