import Button from '@/common/button';
import Image from '@/common/image';
import PaymentCard from '@/care-credit/components/payment-calculator';
import { AltLayout } from '../../layouts/AltLayout';

const CareCredit = () => {
    return (
        <AltLayout
            heroButtonText="Get In Touch With Us"
            heroImg="CareCreditHero.jpg"
            logo="carecreditlogo.png"
            label="Our Team"
            description="We have highly skilled dental assistants and office managers who are always available at your disposal in our office"
        >
            <div className="w-full md:flex md:flex-row flex-col md:justify-between  pt-[120px]">
                <div className="max-w-[840px] w-full  mx-auto md:mx-0">
                    <Image filename="calculator.png" alt="calculator" className="w-[45px]" />
                    <p className="DH3MH3 text-blackT pt-5 pb-4 md:pt-10 md:pb-8  2xl:max-w-[850px]">
                        CareCredit Healthcare Credit Card For Dental Procedure Financing
                    </p>
                    <a href="#contact">
                        <Button
                            className="text-primary border-2 border-primary mb-8 md:mb-6"
                            text="Get In Touch With Us"
                            greenRightArrow
                        />
                    </a>
                </div>
                <PaymentCard />
            </div>
            <div className="pt-[76px] pb-[100px] max-w-[1500px]">
                <p className="DP1MP1 text-blackT mb-10">
                    We are pleased to accept the CareCredit healthcare, credit card. CareCredit lets
                    you say Yes to recommended surgical and non-surgical dental procedures like
                    crowns, oral surgery or even a general checkup, and pay for them in convenient
                    monthly payments that fit your financial situation.
                </p>
                <p className="DP1MP1 text-blackT mb-10">
                    CareCredit is a credit card that helps you pay for treatments and procedures for
                    you or your family that may not be covered by insurance. With special financing
                    options,* you can use your CareCredit card again and again for your dental
                    needs, as well as at thousands of other healthcare providers, including
                    optometrists, veterinarians, ophthalmologists and hearing specialists.
                </p>
                <p className="DP1MP1 text-blackT mb-10">
                    Its free and easy to apply and youll receive a decision immediately. If youre
                    approved, you can schedule your procedures even before you receive your card.
                    With more than 21 million accounts opened since CareCredit began nearly 30 years
                    ago, they are the trusted source for healthcare credit cards.
                </p>
                <p className="DP1MP1 text-blackT mb-10">
                    Learn more by visiting www.carecredit.com or contacting our office. Ready to
                    apply? Apply online for your CareCredit healthcare credit card today.
                </p>
                <p className="DP1MP1 text-blackT">Youll be smiling brighter in no time!</p>
            </div>
        </AltLayout>
    );
};

export default CareCredit;
